import _ from 'lodash';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import MedicalScoreParamType from '../../model/enums/medical-score-param-type';
import { ResultType } from '../../model/medical-calculator/result-type';
import { MedicalScoreParam } from '../../model/medical-score/medical-score-param';
import { MedicalScoreParamGroup } from '../../model/medical-score/medical-score-param-group';
import { createFunctionByString } from '../../shared/util/markdown-utils';
import {
    CalculatorResultContainer,
    MainContainer,
    ResultContainer,
    ResultText,
    ResultTypeContainer,
    ResultTypeText,
    Title
} from './medical-score-styles';
import { useMedicalScoreContext } from './medical-score.provider';
import './medical-score.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
    t: any;
}

const MedicalScoreResult = (props: Props) => {
    const { result, setResult, paramValues, medicalScore } = useMedicalScoreContext();

    useEffect(() => {
        if (medicalScore !== undefined) {
            const allParamsFilled = Object.values(paramValues).every(x => x !== undefined);

            if (allParamsFilled) {
                try {
                    let formulaParams = [...medicalScore.formulaParams!, ...medicalScore.formulaParamGroups!];
                    const params: any[] = [];
                    _.sortBy(formulaParams, 'position').forEach(it => {
                        if ('formulaParams' in it) {
                            const formulaParamGroup = it as MedicalScoreParamGroup;
                            _.sortBy(formulaParamGroup.formulaParams, 'position').map(param =>
                                params.push(getValueOfFormulaParam(param as MedicalScoreParam))
                            );
                        } else {
                            params.push(getValueOfFormulaParam(it as MedicalScoreParam));
                        }
                    });
                    console.log({ params });
                    let newResult = createFunctionByString(`var run1 = ${medicalScore.formula!}; run1(${[...params]});`);
                    setResult(newResult);
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [paramValues]);

    const getValueOfFormulaParam = (formulaParam: MedicalScoreParam) => {
        const param = paramValues[formulaParam.code!];
        switch (formulaParam.inputType) {
            case MedicalScoreParamType.MULTI_ANSWER_SELECT:
                console.log({param})
                const result = param.answers.map(answer => `{code: '${answer.code}', points: ${answer.points}}`).join(',');
                return `{answers: [${result}]}`;
            case MedicalScoreParamType.MULTI_SELECT:
            case MedicalScoreParamType.MULTI_SELECT_TOGGLE_FIRST:
                return `{code: '${param.code}', points: ${param.points}}`;
            case MedicalScoreParamType.INPUT_INTEGER:
                return Number(paramValues[formulaParam.code!] as string);
            case MedicalScoreParamType.INPUT:
                return Number(_.replace(paramValues[formulaParam.code!] as string, ',', '.'));
        }
        return param;
    };

    const formatScoreResult = (text: string) => {
        if (isNaN(Number(result.resultType?.title))) {
            return text;
        }
        return `${text} pontos`;
    };

    return (
        <MainContainer>
            {result.resultText !== undefined ? (
                <CalculatorResultContainer>
                    <Title>{props.t(['medicalCalculator.result'])}</Title>
                    <ResultContainer type={result.resultType?.type ?? ResultType.NONE}>
                        {result.resultType != null && result.resultType?.type !== ResultType.NONE && (
                            <ResultTypeContainer type={result.resultType?.type ?? ResultType.NORMAL}>
                                <ResultTypeText>{formatScoreResult(result.resultType?.title) ?? ''}</ResultTypeText>
                            </ResultTypeContainer>
                        )}
                        <ResultText>{_.replace(result.resultText, /\*\*/gm, '')}</ResultText>
                    </ResultContainer>
                </CalculatorResultContainer>
            ) : (
                <div />
            )}
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(MedicalScoreResult) as React.ComponentType<any>;
