import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import IconInput from '../../components/icon-input/icon-input';
import ContentType from '../../model/enums/contentType';
import { PediatricDose } from '../../model/pediatric-dose/pediatric-dose';
import { PediatricDoseCategory } from '../../model/pediatric-dose/pediatric-dose-category';
import { RecentHistory } from '../../model/recentHistory';
import { addInRecents } from '../../reducer/recentHistory/actions';
import { usePediatricDoseContext } from './pediatric-dose.provider';
import './pediatric-dose.scss';
import PediatricDoseService from '../../services/pediatric-dose.service';

interface Props {
    t: any;
    addInRecents: (recent: RecentHistory) => void;
}

interface PediatricDoseSection {
    category: PediatricDoseCategory;
    items: PediatricDose[];
}

const PediatricDoseList = (props: Props) => {
    const { pediatricDoseList, setPediatricDoseList, setNewCurrentPediatricDose, setInput } = usePediatricDoseContext();
    const [searchText, setSearchText] = useState<string>('');
    const [sections, setSections] = useState<PediatricDoseSection[]>([]);

    useEffect(() => {
        if (pediatricDoseList.length > 0) {
            setSectionsByCategory(pediatricDoseList);
        }
    }, [pediatricDoseList]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            PediatricDoseService.search(searchText).then(doses => {
                setPediatricDoseList(doses);
                setSectionsByCategory(pediatricDoseList);
            });
            // const text = searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            // const newList = pediatricDoseList.filter(
            //     dose =>
            //         dose.normalizedName?.includes(text) ||
            //         (dose.synonyms?.filter(synonym => synonym.normalizedName?.includes(text))?.length ?? 0) > 0
            // );
            // setSectionsByCategory(newList);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchText]);

    const setSectionsByCategory = (doses: PediatricDose[]) => {
        const categories = _.uniqBy(
            doses.filter(it => it.category != null).map(it => it.category),
            'id'
        );
        setSections(
            categories.map(category => ({
                category: category!,
                items: doses.filter(it => it.category?.id === category?.id)
            }))
        );
    };

    const onPressPediatricDose = (dose: PediatricDose) => {
        setInput('', true);
        setNewCurrentPediatricDose(dose);
        props.addInRecents({ name: dose.name!, contentId: dose.id!, type: ContentType.PEDIATRIC_DOSE });
    };

    return (
        <div className={'pediatrc-dose-container'}>
            <span className={'search-title'}>{'Doses Pediátricas'}</span>
            <div className={'dose-search-container'}>
                <IconInput value={searchText} onChangeText={setSearchText} />
                <div className={'dose-content-container'}>
                    {sections.map(section => (
                        <div className={'dose-section-container'} key={section?.category.id ?? ''}>
                            <span className={'dose-section-name'}>{section.category?.name ?? ''}</span>
                            {_.sortBy(section.items, 'name').map(pediatricDose => (
                                <div
                                    className={'dose-container'}
                                    onClick={() => onPressPediatricDose(pediatricDose)}
                                    key={pediatricDose.id ?? ''}
                                >
                                    <span className={'dose-name'}>{pediatricDose?.name ?? ''}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    addInRecents: addInRecents
};

export default compose(connect(null, mapDispatchToProps), withTranslation())(PediatricDoseList) as React.ComponentType<any>;
