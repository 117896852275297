import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import Loading from '../../../components/loading/loading';
import FlashcardRunMode from '../../../model/enums/flashcard-run-mode';
import { FlashcardRun, FlashcardRunMetric } from '../../../model/flashcard/flashcard-run';
import FlashcardRunService from '../../../services/flashcard-run.service';
import FlashcardHeader from '../components/flashcard-header';
import FlashcardModalReset from '../components/flashcard-modal-reset/flashcard-modal-reset';
import FlashcardRunCard from '../components/flashcard-run-card/flashcard-run-card';
import { SectionTitleContainer } from '../components/flashcard-run-card/flashcard-run-card.styles';
import { Container, MainContainer } from '../flashcard-styles';
import '../flashcard.scss';
import { ContentContainer, DotsIcon, Subtitle, Title } from './flashcard-extensive-dashboard.styles';

interface Props extends RouteComponentProps<{}, {}, { id?: number }>, WithTranslation {
    hasSubscription?: boolean;
    openPaidModal: () => void;
    onPressPerformanceButton?: () => void;
    hideCreateCardButton?: boolean;
}

type Section = {
    title: number;
    startDate: Moment;
    endDate: Moment;
    isFinished: boolean;
    data: FlashcardRunMetric[];
};

const FlashcardExtensiveDashboard = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sections, setSections] = useState<Section[]>([]);
    const [showResetModal, setShowResetModal] = useState<boolean>(false);

    useEffect(() => {
        FlashcardRunService.getFlashcardRunByMode(FlashcardRunMode.EXTENSIVE).then(it => {
            if (it != null) {
                setExtensiveInformation(it);
            } else {
                props.history.push('/flashcard-extensive-create');
            }
        }).finally(() => setIsLoading(false));
    }, []);

    const setExtensiveInformation = (newExtensive: FlashcardRun) => {
        setIsLoading(true);
        const newSections: Section[] = [];
        const metric: FlashcardRunMetric[] = newExtensive.metric as FlashcardRunMetric[];
        let startDate =
            moment(newExtensive!.startDate).weekday() === 0
                ? moment(newExtensive!.startDate).clone().subtract(1, 'days')
                : moment(newExtensive!.startDate).clone();
        let weekNumber = 1;
        let currentWeek = -1;
        let currentWeekDay = 1;
        while (moment(startDate).isBefore(moment(newExtensive.endDate!))) {
            const checkIfItIsThisWeek = (currentDate: Moment, startWeekDate: Moment): boolean =>
                currentDate.isBetween(startWeekDate.clone().subtract(1, 'days'), startWeekDate.clone().endOf('week').add(1, 'days'));
            const data: FlashcardRunMetric[] = _.reverse(metric.filter(it => checkIfItIsThisWeek(moment(it.date), startDate)));
            if (data.length === 0) {
                startDate = startDate.clone().endOf('week').add(2, 'days');
                weekNumber++;
                continue;
            }
            if (checkIfItIsThisWeek(moment(), startDate)) {
                currentWeek = weekNumber;
                currentWeekDay = data.findIndex(it => moment().isSame(moment(it.date), 'days')) + 1;
            }
            const newSection: Section = {
                title: weekNumber,
                data,
                isFinished: false,
                startDate: startDate.clone().startOf('week').add(1, 'days'),
                endDate: startDate.clone().endOf('week').add(1, 'days')
            };
            weekNumber++;
            startDate = startDate.clone().endOf('week').add(2, 'days');
            newSections.push(newSection);
        }
        setSections(newSections);
        setTimeout(() => {
            const index = currentWeek * currentWeekDay;
            const element = document.getElementsByClassName(`flashcard-run-card-container`)[index === 0 ? metric.length - 1 : metric.length - index];
            element.scrollIntoView({
                behavior: 'smooth'
            });
            setIsLoading(false);
        }, 1200);
    };

    const onBack = () => {
        props.history.push('/flashcard-dashboard');
    };

    const resetExtensive = () => {
        setShowResetModal(false);
        setIsLoading(true);
        FlashcardRunService.resetFlashcardRunByMode(FlashcardRunMode.EXTENSIVE).then(() => {
            props.history.push('/flashcard-dashboard');
        });
    };

    const renderCard = (item: FlashcardRunMetric, key: string) => {
        const cards = sections
            .map(it => it.data)
            .flat(1)
            .sort((a, b) => a.id - b.id);
        let allBeforeIsFinished = false;
        const itemIndex = cards.findIndex(it => it.id === item.id);
        if (itemIndex > 2) {
            allBeforeIsFinished = cards[itemIndex - 1].isFinished || cards[itemIndex - 2].isFinished;
        }

        let isDisabled = !moment(item.date).clone().startOf('week').isSameOrBefore(moment()) && !allBeforeIsFinished;
        const isCheckpointCard = item.id % 1 === 0.5;
        if (isCheckpointCard) {
            const searchId = item.id - 0.5;
            const completeWeekCards = cards.filter(it => it.id === searchId || it.id === searchId - 1).every(it => it.isFinished);
            const isSameWeek = moment().isSameOrBefore(moment(item.date).clone().endOf('week'));
            isDisabled = !completeWeekCards && isSameWeek;
        }
        return (
            <FlashcardRunCard
                key={key}
                metric={item}
                title={item.title!}
                isDisabled={isDisabled}
                onClick={() => props.history.push(isCheckpointCard ? `/flashcard-extensive/checkpoint/${item.id}` : `/flashcard-extensive/${item.id}`)}
                isCheckpoint={isCheckpointCard}
                subtitle={item.subtitle!} />);
    };

    const orderedSections = sections.sort((a, b) => b.title - a.title);
    return isLoading ? (
        <div className="disease-screen-container">
            <Loading />
        </div>
    ) : (
        <MainContainer>
            <Container>
                <FlashcardHeader
                    {...props}
                    title={props.t('flashcardExtensive.subtitle')}
                    subtitle={props.t('flashcardExtensive.title')}
                    viewMode={'CARD'}
                    onPressBack={onBack}>
                    <DotsIcon onClick={() => setShowResetModal(true)} className={'dots-three-vertical-img'} />
                </FlashcardHeader>
                <ContentContainer>
                    {orderedSections.map(section => <div key={section.title}>
                        <SectionTitleContainer>
                            <Title>{`Semana ${section.title}`}</Title>
                            <Subtitle>{` • ${moment(section.startDate).clone().format('DD MMM')} a ${moment(section.endDate).clone().format('DD MMM')}`}</Subtitle>
                        </SectionTitleContainer>
                        {section.data.map((item, i) => renderCard(item, `${section.title}-${i}`))}
                    </div>)}
                </ContentContainer>
                <FlashcardModalReset
                    show={showResetModal}
                    onPressConfirm={resetExtensive}
                    onClose={() => setShowResetModal(false)}
                    title={props.t('flashcardExtensive.resetModalTitle')}
                    subtitle={props.t('flashcardExtensive.resetModalSubtitle')}
                    confirmLabel={props.t('flashcardExtensive.resetModalConfirm')}
                />
            </Container>
        </MainContainer>
    );
};

const mapDispatchToProps = {};

export default compose(connect(null, mapDispatchToProps), withTranslation())(FlashcardExtensiveDashboard) as React.ComponentType<any>;
