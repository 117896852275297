import { SimpleId } from "./simpleId";

export interface Note {
    id?: number;
    type: NoteType;
    date?: string;
    content: string;
    pediatricDose?: SimpleId | null;
    medicalScore?: SimpleId | null;
    medicineLeaflet?: SimpleId | null;
    medicalCriteria?: SimpleId | null;
    semiology?: SimpleId | null;
    laboratory?: SimpleId | null;
    medicalCalculator?: SimpleId | null;
    disease?: SimpleId | null;
    isActive?: boolean;
}

export enum NoteType {
    MEDICINE_LEAFLET = 'MEDICINE_LEAFLET',  
    MEDICAL_CALCULATOR = 'MEDICAL_CALCULATOR',  
    MEDICAL_SCORE = 'MEDICAL_SCORE',  
    LABORATORY = 'LABORATORY',  
    PEDIATRIC_DOSE = 'PEDIATRIC_DOSE',  
    MEDICAL_CRITERIA = 'MEDICAL_CRITERIA',
    SEMIOLOGY = 'SEMIOLOGY',  
    DISEASE = 'DISEASE'
}