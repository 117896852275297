import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { Favorite, FavoriteType } from '../../model/favorite';
import FavoritesService from '../../services/favoritesService';
import { CustomSlider } from '../illustrated-procedures/content/illustrated-procedure-tab';
import { useMedicalCriteriaContext } from './medical-criteria.provider';
import './medical-criteria.scss';

interface Props extends RouteComponentProps<{}, {}, {}> {
  t: any;
}

const MedicalCriteriaHeader = (props: Props) => {
  const [fontSliderValue, setFontSliderValue] = useState<number>(0);
  const {
    setFontSize,
    showAnnotation,
    showResizerModal,
    setShowAnnotation,
    setShowResizerModal,
    medicalCriteria,
    setMedicalCriteria,
    showFavoriteSuccessToast,
    setShowFavoriteSuccessToast,
  } = useMedicalCriteriaContext();

  const onFavorite = async (): Promise<void> => {
    const isFavorited = medicalCriteria?.favorite?.isActive;
    const params: Favorite = {
      type: FavoriteType.MEDICAL_CRITERIA,
      medicalCriteria: {
        id: medicalCriteria?.id ?? 0,
      },
      isActive: isFavorited ? false : undefined,
    };
    setMedicalCriteria({
      ...medicalCriteria,
      favorite: { ...params, isActive: !isFavorited },
    });
    setShowFavoriteSuccessToast(!isFavorited);
    FavoritesService.favorite(params).then(() => {
      setTimeout(() => {
        setShowFavoriteSuccessToast(false);
      }, 3000);
    });
  };

  const onChangeSlider = (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ): void => {
    setFontSize(value as number);
    setFontSliderValue(value as number);
  };

  return (
    <div className={'dose-header-container'}>
      <span className={'criteria-header-name'}>
        {medicalCriteria?.name ?? ''}
      </span>
      <div className={'dose-icons-container'}>
        <div
          onClick={() => {
            setShowAnnotation(!showAnnotation);
          }}
          className={`dose-icon-pencil${
            medicalCriteria?.note?.isActive ? '--on' : ''
          }`}
        />
        <div
          onClick={onFavorite}
          className={`dose-icon-favorite${
            medicalCriteria?.favorite?.isActive ? '--on' : ''
          }`}
        />
        <div
          onClick={() => {
            setShowResizerModal(!showResizerModal);
          }}
          className={`dose-icon-font-resizer${showResizerModal ? '--on' : ''}`}
        />
      </div>
      {showResizerModal && (
        <div
          className="disease-content__slider-container"
          style={{ top: '194px', right: '26%' }}
        >
          <CustomSlider
            className="disease-content__slider"
            value={fontSliderValue}
            max={17}
            min={13}
            step={2}
            marks={[{ value: 15, label: '' }]}
            onChange={onChangeSlider}
          />
        </div>
      )}
      {showFavoriteSuccessToast && (
        <div
          className="disease-content__favorite-success-toast"
          style={{ top: '193px', right: '30.4vw' }}
        >
          <div className="disease-content__white-star-img" />
          <span className="disease-content__favorite-success-msg">
            {props.t('cid.favoriteSuccessMessage')}
          </span>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {};

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation()
)(MedicalCriteriaHeader) as React.ComponentType<any>;
