import { Slider, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../App.scss';
import { Disease } from '../../model/disease';
import { Favorite, FavoriteType } from '../../model/favorite';
import { IRootState } from '../../reducer';
import FavoritesService from '../../services/favoritesService';
import DiseaseSearch from './disease-search';

const CustomSlider = withStyles({
    root: {
        color: '#e7e7e7',
        height: 4,
        opacity: 1
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.32)',
        backgroundColor: '#ffffff',
        marginTop: -6
    },
    mark: {
        backgroundColor: '#e7e7e7',
        height: 9,
        width: 4,
        borderRadius: 3,
        marginTop: -2.02,
        zIndex: -1
    },
    active: {},
    track: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundImage: 'linear-gradient(90deg, rgba(44,147,147,1) 0%, rgba(66,210,206,1) 100%)'
    },
    rail: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundColor: '#e7e7e7'
    }
})(Slider);

export interface DiseaseTabProps extends RouteComponentProps<{}> {
    t: any;
    disease: Disease;
    diagnostic: () => JSX.Element;
    treatment: () => JSX.Element;

    onChangeFontSize: (fontSize: number) => void;
    onShowAnnotation: () => void;
    onChangeTab: (tab: DiseaseTabEnum) => void;

    title: string;
    cid: string;
    onBack: () => void;

    annotationIsOpen?: boolean;
}

export interface DiseaseTabState {
    currentTab: DiseaseTabEnum;

    showFontSlider?: boolean;
    fontSliderValue: number;
    fontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

export enum DiseaseTabEnum {
    DIAGNOSTIC = 'DIAGNOSTIC',
    TREATMENT = 'TREATMENT'
}

export class DiseaseTab extends React.Component<DiseaseTabProps, DiseaseTabState> {
    private sliderRef;

    constructor(props: Readonly<DiseaseTabProps>, context?: any) {
        super(props, context);

        this.state = {
            currentTab: DiseaseTabEnum.DIAGNOSTIC,
            fontSliderValue: 0,
            fontSize: 13,
            isFavorited: props.disease.favorite && props.disease.favorite.isActive
        };
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    private handleClickOutside = (event: any): void => {
        if (this.sliderRef && !this.sliderRef.contains(event.target) && this.state.showFontSlider) {
            this.setState({
                showFontSlider: false
            });
        }
    };
    private onClickTab = (tab: DiseaseTabEnum) => {
        this.setState({
            currentTab: tab
        });
        this.props.onChangeTab(tab);
    };
    private onChangeSlider = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
        this.setState({
            fontSliderValue: value as number,
            fontSize: value as number
        });
        this.props.onChangeFontSize(value as number);
    };
    private onResizeFont = () => {
        this.setState({
            showFontSlider: !this.state.showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { isFavorited } = this.state;
        const { disease } = this.props;
        const params: Favorite = {
            type: FavoriteType.DISEASE,
            disease: {
                id: disease.id
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onAnnotation = () => {
        this.props.onShowAnnotation();
    };

    render(): JSX.Element {
        const { diagnostic, treatment, disease, onBack, title, cid } = this.props;
        const { currentTab, showFontSlider, fontSliderValue, showFavoriteSuccessToast, isFavorited } = this.state;

        const contentRender: () => JSX.Element = currentTab === DiseaseTabEnum.DIAGNOSTIC ? diagnostic : treatment;
        return (
            <div className="tab">
                <div className="tab__menu-containter">
                    <div className="tab__menu">
                        <div className="tab__menu__left">
                            <div className="tab__menu__left__title">
                                <div className="screen__title">
                                    <div className="screen__title__back" onClick={onBack}>
                                        <div className="screen__title__back__img" />
                                    </div>
                                    <span className="screen__title__text">{title}</span>
                                    {cid != null && (
                                        <div className="screen__title__cid">
                                            <span className="screen__title__cid__text">{`CID: ${cid}`}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tab__menu__left__tabs">
                                {disease.diagnostic && disease.treatment && (
                                    <a
                                        className={`tab__menu__tab ${
                                            currentTab === DiseaseTabEnum.DIAGNOSTIC ? 'tab__menu__tab--selected' : ''
                                        }`}
                                        onClick={() => this.onClickTab(DiseaseTabEnum.DIAGNOSTIC)}
                                    >
                                        <span
                                            className="tab__menu__tab__text">{this.props.t('disease.diagnostic')}</span>
                                    </a>
                                )}

                                {disease.treatment && disease.diagnostic && (
                                    <a
                                        className={`tab__menu__tab ${
                                            currentTab === DiseaseTabEnum.TREATMENT ? 'tab__menu__tab--selected' : ''
                                        }`}
                                        onClick={() => this.onClickTab(DiseaseTabEnum.TREATMENT)}
                                    >
                                        <span
                                            className="tab__menu__tab__text">{this.props.t('disease.treatment')}</span>
                                    </a>
                                )}
                            </div>
                        </div>
                        <DiseaseSearch
                            fontSliderIsOpen={this.state.showFontSlider}
                            annotationIsOpen={this.props.annotationIsOpen}
                            isFavorited={isFavorited}
                            onResizeFont={this.onResizeFont}
                            onFavorite={this.onFavorite}
                            onAnnotation={this.onAnnotation}
                            disease={this.props.disease}
                        />
                        {showFontSlider && (
                            <div className="disease-content__slider-container" ref={ref => (this.sliderRef = ref)}>
                                <CustomSlider
                                    className="disease-content__slider"
                                    value={fontSliderValue}
                                    max={17}
                                    min={13}
                                    step={2}
                                    marks={[{ value: 15, label: '' }]}
                                    onChange={this.onChangeSlider}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tab__content">{contentRender()}</div>

                {showFavoriteSuccessToast && (
                    <div className="disease-content__favorite-success-toast">
                        <div className="disease-content__white-star-img" />
                        <span
                            className="disease-content__favorite-success-msg">{this.props.t('cid.favoriteSuccessMessage')}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(DiseaseTab) as React.ComponentType<any>;
