import { Slider, withStyles } from '@material-ui/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import '../../../App.scss';
import LaboratoryContent from '../../../model/enums/laboratoryContent';
import { Favorite, FavoriteType } from '../../../model/favorite';
import { Laboratory } from '../../../model/laboratory/laboratory';
import { IRootState } from '../../../reducer';
import FavoritesService from '../../../services/favoritesService';
import LaboratorySearch from './laboratory-info-search';

const CustomSlider = withStyles({
    root: {
        color: '#e7e7e7',
        height: 4,
        opacity: 1
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.32)',
        backgroundColor: '#ffffff',
        marginTop: -6
    },
    mark: {
        backgroundColor: '#e7e7e7',
        height: 9,
        width: 4,
        borderRadius: 3,
        marginTop: -2.02,
        zIndex: -1
    },
    active: {},
    track: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundImage: 'linear-gradient(90deg, rgba(44,147,147,1) 0%, rgba(66,210,206,1) 100%)'
    },
    rail: {
        height: 4,
        borderRadius: 3,
        opacity: 1,
        backgroundColor: '#e7e7e7'
    }
})(Slider);

interface Props extends RouteComponentProps<{}> {
    t: any;
    laboratory: Laboratory;
    general: () => JSX.Element;
    collect: () => JSX.Element;
    changes: () => JSX.Element;
    observations: () => JSX.Element;

    onChangeFontSize: (fontSize: number) => void;
    onShowAnnotation: () => void;
    onChangeTab: (tab: LaboratoryContent) => void;

    onBack: () => void;

    annotationIsOpen?: boolean;
}

interface State {
    currentTab: LaboratoryContent;

    showFontSlider?: boolean;
    fontSliderValue: number;
    fontSize: number;

    isFavorited?: boolean;
    showFavoriteSuccessToast?: boolean;
}

export class LaboratoryTab extends React.Component<Props, State> {
    private sliderRef;
    constructor(props: Readonly<Props>, context?: any) {
        super(props, context);

        this.state = {
            currentTab: LaboratoryContent.GENERAL,
            fontSliderValue: 0,
            fontSize: 13,
            isFavorited: props.laboratory?.favorite && props.laboratory?.favorite?.isActive
        };
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    private handleClickOutside = (event: any): void => {
        if (this.sliderRef && !this.sliderRef.contains(event.target) && this.state.showFontSlider) {
            this.setState({
                showFontSlider: false
            });
        }
    };
    private onClickTab = (tab: LaboratoryContent) => {
        this.setState({
            currentTab: tab
        });
        this.props.onChangeTab(tab);
    };
    private onChangeSlider = (event: React.ChangeEvent<{}>, value: number | number[]): void => {
        this.setState({
            fontSliderValue: value as number,
            fontSize: value as number
        });
        this.props.onChangeFontSize(value as number);
    };
    private onResizeFont = () => {
        this.setState({
            showFontSlider: !this.state.showFontSlider
        });
    };
    private onFavorite = async (): Promise<void> => {
        const { isFavorited } = this.state;
        const { laboratory } = this.props;
        const params: Favorite = {
            type: FavoriteType.LABORATORY,
            laboratory: {
                id: laboratory.id!
            },
            isActive: isFavorited ? false : undefined
        };
        FavoritesService.favorite(params).then(() => {
            this.setState(
                {
                    isFavorited: !isFavorited,
                    showFavoriteSuccessToast: !isFavorited
                },
                () => {
                    setTimeout(() => this.setState({ showFavoriteSuccessToast: false }), 3000);
                }
            );
        });
    };
    private onAnnotation = () => {
        this.props.onShowAnnotation();
    };

    private getCorrectRender = (): JSX.Element => {
        switch (this.state.currentTab) {
            case LaboratoryContent.GENERAL:
                return this.props.general();
            case LaboratoryContent.COLLECT:
                return this.props.collect();
            case LaboratoryContent.CHANGES:
                return this.props.changes();
            case LaboratoryContent.OBSERVATIONS:
                return this.props.observations();
            default:
                return <div />;
        }
    }

    private renderTab = (type: LaboratoryContent) => {
        return (
            <a
                className={`tab__menu__tab ${this.state.currentTab === type ? 'tab__menu__tab--selected' : ''}`}
                onClick={() => this.onClickTab(type)}
            >
                <span className="tab__menu__tab__text">{this.props.t(`enum.laboratoryContent.${type}`)}</span>
            </a>
        )
    }

    render(): JSX.Element {
        const { laboratory, onBack } = this.props;
        const { showFontSlider, fontSliderValue, showFavoriteSuccessToast, isFavorited } = this.state;

        return (
            <div className="tab">
                <div className="tab__menu-containter">
                    <div className="tab__menu">
                        <div className="tab__menu__left">
                            <div className="tab__menu__left__tabs">
                                {this.renderTab(LaboratoryContent.GENERAL)}
                                {this.renderTab(LaboratoryContent.CHANGES)}
                                {this.renderTab(LaboratoryContent.COLLECT)}
                                {this.renderTab(LaboratoryContent.OBSERVATIONS)}
                            </div>
                        </div>
                        <LaboratorySearch
                            fontSliderIsOpen={this.state.showFontSlider}
                            annotationIsOpen={this.props.annotationIsOpen}
                            isFavorited={isFavorited}
                            onResizeFont={this.onResizeFont}
                            onFavorite={this.onFavorite}
                            onAnnotation={this.onAnnotation}
                            laboratory={laboratory}
                        />
                        {showFontSlider && (
                            <div className="disease-content__slider-container" ref={ref => (this.sliderRef = ref)}>
                                <CustomSlider
                                    className="disease-content__slider"
                                    value={fontSliderValue}
                                    max={17}
                                    min={13}
                                    step={2}
                                    marks={[{ value: 15, label: '' }]}
                                    onChange={this.onChangeSlider}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="tab__content">{this.getCorrectRender()}</div>

                {showFavoriteSuccessToast && (
                    <div className="disease-content__favorite-success-toast">
                        <div className="disease-content__white-star-img" />
                        <span className="disease-content__favorite-success-msg">{this.props.t('cid.favoriteSuccessMessage')}</span>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LaboratoryTab) as React.ComponentType<any>;
